<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-popup-submenu></q-popup-submenu>
        <!-- Charts-->
        <v-row class="mx-5" v-if="loading">
            <v-col cols="12">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
        </v-row>
        <v-form class="mx-5" ref="form" v-model="form_valid" v-else>
            <v-row justify="space-between" align="stretch" class="mx-5">
                <v-col cols="12">
                    <h3>{{ pageTitle }}</h3>
                </v-col>
                <v-col cols="12" sm="12" lg="6" class="cms">
                    <v-text-field 
                        :rules="[form_rules.required]" 
                        required 
                        v-model="popup_notice.title"
                        label="Title"
                    />

                    <template v-if="send_sms">
                        <v-textarea v-model="popup_notice.content" counter
                            :rules="[(v) => isOnlyAsciiExtended(v) || 'Only ASCII characters allowed here']"
                            :maxlength="160" />
                    </template>
                    <template v-else>
                        <FMLPopupContentEditor v-if="popup_notice.content !== null && popup_notice.content !== undefined" v-model="popup_notice.content" />
                    </template>
                    <span style="float:right;" class="overline mt-2">
                        Character Count:
                        <span :class="characterCount() > 160 ? 'red--text font-weight-black' : ''">
                            {{ characterCount() }}
                        </span>
                    </span>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on">
                                Merge Fields
                                <v-icon right>fa-solid fa-caret-down</v-icon>
                            </v-btn>
                        </template>

                        <v-list dense>
                            <v-list-item v-for="field in mergeFields" :key="field" class="d-flex justify-space-between">
                                <span>{{ field }}</span>
                                <v-icon @click.stop="copy(field)" right>fas fa-copy</v-icon>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-row>
                        <v-col cols="12" sm="4" class="py-2 px-4">
                            <v-switch v-model="needs_link" label="Needs Link"></v-switch>
                        </v-col>
                        <template v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])">
                            <v-col cols="12" sm="4" class="px-4">
                                <v-switch v-model="send_sms" class="mt-2">
                                    <template v-slot:label>
                                        Send SMS
                                        <v-btn small fab icon @click.stop="show_preview_sms_form = true">
                                            <v-icon>fas fa-eye</v-icon>
                                        </v-btn>
                                    </template>
                                </v-switch>
                            </v-col>
                            <v-col cols="12" sm="4" class="px-4">
                                <v-switch v-model="send_sms_only">
                                    <template v-slot:label>
                                        SMS Only
                                    </template>
                                </v-switch>
                            </v-col>
                        </template>
                        <v-col cols="12" sm="6" v-if="needs_link">
                            <v-text-field v-model="popup_notice.link_text" label="Button Text"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="needs_link">
                            <v-text-field v-model="popup_notice.link" label="Button Link"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" v-if="needs_link">
                            <v-switch v-model="popup_notice.add_acknowledge_btn"
                                label="Add seperate Acknowledge button"></v-switch>
                        </v-col>
                    </v-row>
                    <template v-if="$vuetify.breakpoint.lgAndUp">
                        <v-btn :large="$vuetify.breakpoint.smAndUp"
                            :width="$vuetify.breakpoint.smAndDown ? '100%' : 'auto'" color="q_messages_3"
                            @click="previewPopup" outlined class="mr-2 mb-2">Preview Message</v-btn>
                        <v-btn :large="$vuetify.breakpoint.smAndUp"
                            :width="$vuetify.breakpoint.smAndDown ? '100%' : 'auto'" color="q_messages_3"
                            @click="savePopup" depressed class="mb-2" :disabled="!form_valid">Save & Send
                            Message</v-btn>
                        <v-alert type="info" class="mt-4 text-left">Note: If you are editing an existing notice, anyone
                            who has already acknowledged the previous notice <em>will also see the newly edited
                                one</em>.</v-alert>
                        <v-alert type="info" class="mt-4 text-left">
                            Note about SMS messages: The title will not be included in the message, only the main
                            content will be sent. When sending an SMS message, the message will be sent immediately. It
                            cannot be scheduled for a future date. You must also set Active to the on position.
                        </v-alert>
                    </template>
                </v-col>
                <v-col cols="12" sm="12" lg="6" class="px-4">
                    <v-switch v-model="popup_notice.active" :label="popup_notice.active == 1 ? 'Active' : 'Not Active'"
                        value="1" :true-value="parseInt('1')" :false-value="parseInt('0')"></v-switch>
                    <v-menu v-model="startDateSelector" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="popup_notice.startDate" label="Start Date" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="popup_notice.startDate"
                            @input="startDateSelector = false"></v-date-picker>
                    </v-menu>
                    <v-menu v-model="endDateSelector" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="popup_notice.endDate" label="End Date" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="popup_notice.endDate" @input="endDateSelector = false"></v-date-picker>
                    </v-menu>
                    <v-select :rules="[form_rules.required]" label="Target Audience" :items="available_audiences"
                        required v-model="popup_notice.audience"></v-select>
                    <v-row v-if="hasRole('AgencyOwner')">
                        <v-col cols="12" v-if="popup_notice.audience == 'agents'">
                            <div>
                                <v-chip small close @click:close="removeAgent(key)"
                                    v-for="(agent, key) in popup_notice.selected_agents" :key="key" class="ma-1">{{
                                        agent.AgentName
                                    }}</v-chip>
                            </div>
                            <v-text-field v-model="agent_search" label="Search for Agent"></v-text-field>
                            <v-progress-linear v-if="agents_loading" indeterminate color="primary"></v-progress-linear>
                            <v-list class="result-list elevation-1">
                                <v-list-item v-for="(agent, key) in available_agents" :key="key" @click="addAgent(key)">
                                    {{ agent.AgentName }}
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" v-if="popup_notice.audience == 'agent_codes'">
                            <v-textarea v-model="pasted_agents" label="Agent Codes"
                                messages="Enter comma delimated AgentCodes or OptIDs"></v-textarea>
                        </v-col>
                        <v-col cols="12"
                            v-else-if="!['company', 'Corporate', 'Field', 'Wholesale', 'trigger_fast_track_completed', 'trigger_qms_commission'].includes(popup_notice.audience) && popup_notice.audience != null">
                            <div>
                                <v-chip small close @click:close="removeAgent(key)"
                                    v-for="(agent, key) in popup_notice.selected_agents" :key="key" class="ma-1">{{
                                        agent.AgentName
                                    }}</v-chip>
                            </div>
                            <v-text-field v-model="agent_search" label="Search for Agent"></v-text-field>
                            <v-progress-linear v-if="agents_loading" indeterminate color="primary"></v-progress-linear>
                            <v-list class="result-list elevation-1">
                                <v-list-item v-for="(agent, key) in available_agents" :key="key" @click="addAgent(key)">
                                    {{ agent.AgentName }}
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row v-if="popup_notice.filters">
                        <v-col cols="12">
                            Filters:
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-menu v-model="contractStartDateSelector" :close-on-content-click="false"
                                :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="popup_notice.filters.contractStartDate"
                                        label="Contract Start: From Date" readonly v-bind="attrs" v-on="on"
                                        clearable></v-text-field>
                                </template>
                                <v-date-picker v-model="popup_notice.filters.contractStartDate"
                                    @input="contractStartDateSelector = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-menu v-model="contractEndDateSelector" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="popup_notice.filters.contractEndDate"
                                        label="Contract Start: To Date" readonly v-bind="attrs" v-on="on"
                                        clearable></v-text-field>
                                </template>
                                <v-date-picker v-model="popup_notice.filters.contractEndDate"
                                    @input="contractEndDateSelector = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <v-select v-model="popup_notice.filters.contractLevel"
                                :items="[70, 75, 80, 85, 90, 95, 100, 110, 120, 130]" :menu-props="{ maxHeight: '400' }"
                                label="Contract Levels" multiple></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select v-model="popup_notice.filters.leadershipLevel"
                                :items="['Sales Representative', 'Team Leader', 'Key Leader', 'Agency Owner', 'Agency Director', 'Regional Agency Director', 'Managing Vice President', 'Senior Vice President', 'Executive Vice President', 'Associate Partner', 'Senior Partner', 'Managing Partner', 'SFG']"
                                :menu-props="{ maxHeight: '400' }" label="Leadership Levels" multiple></v-select>
                        </v-col>
                        <!-- Filter by isAdvisoryBoardMember -->
                        <v-col cols="12" sm="6">
                            <v-checkbox v-model="popup_notice.filters.isAdvisoryBoardMember"
                                label="Advisory Board Member">
                            </v-checkbox>

                            <!-- Filter by PCSAgentInd -->

                            <v-checkbox v-model="popup_notice.filters.PCSAgentInd" label="PCS Agent">
                            </v-checkbox>

                            <!-- Filter by SFG Direct -->

                            <v-checkbox v-model="popup_notice.filters.IsSFGDirect" label="SFG Direct (130)">
                            </v-checkbox>

                            <!-- Filter by DFLCertifiedInd -->

                            <v-checkbox v-model="popup_notice.filters.DFLCertifiedInd" label="DFL Certified">
                            </v-checkbox>

                            <!-- Filter by IULCertifiedInd -->

                            <v-checkbox v-model="popup_notice.filters.IULCertifiedInd" label="IUL Certified">
                            </v-checkbox>

                            <!-- Filter by AnnuityCertifiedInd -->

                            <v-checkbox v-model="popup_notice.filters.AnnuityCertifiedInd" label="Annuity Certified">
                            </v-checkbox>
                        </v-col>
                        <!-- Filter by State -->
                        <v-col cols="12" sm="6">
                            <v-select label="State" :items="states" v-model="popup_notice.filters.State"></v-select>
                        </v-col>
                        <!-- <v-col cols="12">
                            Fast Track
                            <v-radio-group v-model="popup_notice.filters.fastTrack" row>
                                <v-radio label="Complete" value="1"></v-radio>
                                <v-radio label="Not Complete" value="-1"></v-radio>
                                <v-radio label="N/A" value="0"></v-radio>
                            </v-radio-group>
                        </v-col> -->
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mx-5">
                <v-col cols="12" v-if="!$vuetify.breakpoint.lgAndUp">
                    <v-btn :large="$vuetify.breakpoint.smAndUp" :width="$vuetify.breakpoint.smAndDown ? '100%' : 'auto'"
                        color="q_messages_3" @click="previewPopup" outlined class="mr-2 mb-2">Preview Message</v-btn>
                    <v-btn :large="$vuetify.breakpoint.smAndUp" :width="$vuetify.breakpoint.smAndDown ? '100%' : 'auto'"
                        color="q_messages_3" @click="savePopup" class="mb-2" :disabled="!form_valid">Save
                        Message</v-btn>
                    <v-alert type="info" class="mt-4 text-left">Note: If you are editing an existing notice, anyone who
                        has already acknowledged the previous notice <em>will also see the newly edited
                            one</em>.</v-alert>
                    <v-alert type="info" class="mt-4 text-left">
                        Note about SMS messages: The title will not be included in the message, only the main content
                        will be sent. When sending an SMS message, the message will be sent immediately. It cannot be
                        scheduled for a future date. You must also set Active to the on position.
                    </v-alert>
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="show_preview_sms_form" width="500" persistent>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Preview SMS
                </v-card-title>
                <v-card-text>
                    <p>You can see a sample sms message by entering your mobile phone number below.</p>
                    <v-text-field v-model="preview_phone_number" label="Mobile phone number"></v-text-field>
                    <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click.stop="show_preview_sms_form = false">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" @click.stop="previewSMS()">
                        Send
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showMessagePreview" width="100%" max-width="960px">
            <MessageCard
                v-if="popup_notice" 
                :message="popup_notice"
                do-not-submit-fml
                @close="showMessagePreview = false"
            />
        </v-dialog>
        <v-snackbar v-model="copiedSnackbar" color="success">
            Copied!
        </v-snackbar>
    </v-container>
</template>
<script>
import QPopupSubmenu from '@/components/navigation/Submenus/QPopupSubmenu.vue'
import PopupNotice from '@/store/Models/PopupNotice'
import TextEditor from '@/CMS/editor/components/TextEditor.vue'
import MinimalTextEditor from '@/CMS/editor/components/MinimalTextEditor.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Agent from '@/store/Models/Agent'
import { debounce } from 'vue-debounce'
import QTooltipDetails from '@/components/utils/QTooltipDetails.vue';
import QAdminNotices from '@/components/utils/QAdminNotices.vue'
import StateOptions from '@/store/StateOptions'
import { FormRenderer } from '@/modules/FML'
import { addFMLToken, hasFMLToken, removeFMLToken } from '@/modules/messages'
import MessageCard from '@/modules/messages/components/MessageCard.vue'
import FMLPopupContentEditor from './FMLPopupContentEditor.vue'

const fields = [
	'{{AgentCode}}',
	'{{AgentName}}',
	'{{AgentID}}',
	'{{AgentEmail}}',
	'{{AgentPhone}}',
	'{{UplineAgentName}}',
	'{{NextRankedUplineAgentName}}',
	'{{NextRankedUplineAgentPreferredName}}',
	'{{BaseShopOwnerName}}',
	'{{SFGDirectName}}',
]

export default {
    name: "PopupNoticeEdit",
    data() {
        return {
            showFmlPreview: false,
            copiedSnackbar: false,
            needs_link: false,
            send_sms: false,
            send_sms_only: false,
            selectedAgentCode: null,
            popup_notice: {
                content: '',
                title: null,
                selected_agents: null,
                preview: false,
                active: 0,
                filters: {
                    State: null,
                    contractStartDate: null,
                    contractEndDate: null,
                    IsSFGDirect: null,
                    PCSAgentInd: null,
                    DFLCertifiedInd: null,
                    AnnuityCertifiedInd: null,
                    IULCertifiedInd: null
                },
                add_acknowledge_btn: false,
            },
            agent_search: null,
            show_search_results: false,
            form_valid: false,
            available_agents: [],
            pasted_agents: '',
            startDateSelector: false,
            endDateSelector: false,
            showMessagePreview: false,
            contractStartDateSelector: false,
            contractEndDateSelector: false,
            show_preview_sms_form: false,
            preview_phone_number: localStorage.getItem("preview_phone_number"),
            states: StateOptions, // Populating the state drop downdown list
            mergeFields: fields,
        };
    },
    mounted: function () {
        if (this.$route.params.id) {
            this.loadNotice(this.$route.params.id)
        }
        this.states.unshift('');
    },
    computed: {
        pageTitle() {
            if (this.$route.params.id) {
                return "Edit Message"
            }
            return "Create Message"
        },
        loading() {
            return this.$store.state.entities.popup_notice.loading;
        },
        available_audiences: function () {
            if (this.hasRole('AgencyOwner')) {
                return [{
                    text: 'Total Agency',
                    value: 'mastershop'
                }, {
                    text: 'Direct Downline',
                    value: 'directdownline'
                }, {
                    text: 'Base Shop',
                    value: 'baseshop'
                }, {
                    text: 'Selected Agents',
                    value: 'agents'
                }]
            } else {
                return [{
                    text: 'Company Wide',
                    value: 'company'
                }, {
                    text: 'Selected Agent Downline',
                    value: 'directdownline'
                }, {
                    text: 'Selected Agent Base Shop',
                    value: 'baseshop'
                }, {
                    text: 'Selected Agents',
                    value: 'agents'
                }, {
                    text: 'Paste Agent Codes',
                    value: 'agent_codes'
                }, {
                    divider: true,
                    header: 'Triggers'
                }, {
                    text: 'Fast Track Completed',
                    value: 'trigger_fast_track_completed'
                }, {
                    text: 'PCS Commission',
                    value: 'trigger_qms_commission'
                }, {
                    text: 'New Agent',
                    value: 'trigger_new_agents'
				}, {
                    text: '105 Contract Level',
                    value: 'trigger_105_contract_level'
                }, {
                    divider: true,
                    header: 'Divisions'
                }, {
                    text: 'Corporate',
                    value: 'Corporate'
                }, {
                    text: 'Field',
                    value: 'Field'
                }, {
                    text: 'Wholesale',
                    value: 'Wholesale'
                }]
            }
        },
        agents_loading: function () {
            return this.$store.state.entities.agent.loading;
        }
    },
    methods: {
        copy(text) {
            navigator.clipboard.writeText(text).then(() => {
                this.copiedSnackbar = true;
            });
        },
        loadNotice(id) {
            if (id === undefined) {
                return // new popup doesn't need loading
            }
            QuilityAPI.getPopupNotice(id)
                .then((item) => {
					if (item.error) {
						this.showError('Could not load Popup Notice<br>', item.message)
					} else {
						if (id === undefined) {
							this.needs_link = false
							this.send_sms = false
							this.send_sms_only = false
						} else {
							this.needs_link = item.data.link_text != null;
							this.send_sms = item.data.send_sms == true
							this.send_sms_only = item.data.send_sms_only == true
						}
	
						this.popup_notice = item.data;
						if (this.popup_notice.audience == "agent_codes") {
							this.pasted_agents = this.popup_notice.selected_agents.join(",")
						}
						if (this.popup_notice.content === null) {
							this.popup_notice.content = ''
						}
						this.$nextTick(() => {
							if (this.send_sms) {
								this.popup_notice.content = item.data.content
							}
						})
						this.loadAvailableAgents(this.popup_notice.audience, false)
						this.$forceUpdate();
					}
                })
                .catch((err) => {
                    this.showError("Whoops! " + err)
                    console.log("error", err)
                    //g.$router.push("/404")
                });
        },
        loadAvailableAgents: debounce(function (type, reset_selected_agents) {
            if (this.hasRole('AgencyOwner')) {
                var agent_code = this.user.AgentCode;
                type = "mastershop"
            } else {
                var agent_code = typeof this.selectedAgentCode == 'undefined' ? null : this.selectedAgentCode;
            }
            this.show_search_results = true
            const isAgencyOwner = this.hasRole('AgencyOwner');

            QuilityAPI.getAgentDownlineSearch(agent_code, {
                simplesearch: this.agent_search,
                "Status": "Active",
                limit: 20
            }, type, isAgencyOwner, ({ data }) => {
                this.available_agents = data;
                Agent.create({ data: data });
            });
        }, 500),
        addAgent(key) {
            var a = { "AgentCode": this.available_agents[key].AgentCode, "AgentName": this.available_agents[key].AgentName };
            if (this.popup_notice.audience == 'agents') {
                if (this.popup_notice.selected_agents == null) {
                    this.$set(this.popup_notice, 'selected_agents', {});
                }
                this.$set(this.popup_notice.selected_agents, a.AgentCode, a);
                this.available_agents.splice(key, 1);
            } else {
                this.popup_notice.selected_agents = {};
                this.$set(this.popup_notice.selected_agents, a.AgentCode, a);
                //Agent.create({ data: [] })
                this.agent_search = null
            }
        },
        removeAgent(code) {
            let g = this;
            let a = g.popup_notice.selected_agents[code];
            g.available_agents.push(a);
            g.$delete(g.popup_notice.selected_agents, code);
        },
        savePopup() {
            var g = this
            if (g.popup_notice.audience == 'agent_codes') {
                g.popup_notice.selected_agents = g.pasted_agents.split(",")
            }
            
            g.popup_notice.AgentCode = g.user.AgentCode
            g.popup_notice.send_sms = g.send_sms
            g.popup_notice.send_sms_only = g.send_sms_only
            g.popup_notice.active = g.popup_notice.active != 1 ? 0 : 1
            g.popup_notice.filters.PCSAgentInd = g.popup_notice.filters.PCSAgentInd != 1 ? 0 : 1
            g.popup_notice.filters.IsSFGDirect = g.popup_notice.filters.IsSFGDirect != 1 ? 0 : 1
            g.popup_notice.filters.DFLCertifiedInd = g.popup_notice.filters.DFLCertifiedInd != 1 ? 0 : 1
            g.popup_notice.filters.AnnuityCertifiedInd = g.popup_notice.filters.AnnuityCertifiedInd != 1 ? 0 : 1
            g.popup_notice.filters.IULCertifiedInd = g.popup_notice.filters.IULCertifiedInd != 1 ? 0 : 1
            g.popup_notice.filters.State = g.popup_notice.filters.State != null ? g.popup_notice.filters.State : null
            g.popup_notice.add_acknowledge_btn = g.popup_notice.add_acknowledge_btn != 1 ? 0 : 1

            if (!this.validateSmsSettings())
                return

            this.$refs.form.validate()
            if (this.form_valid) {
                if (this.popup_notice.id > 0) {
                    QuilityAPI.updatePopupNotice(this.popup_notice).then(function (resp) {
                        if (resp.error) {
                            g.showError(resp.error)
                            return;
                        }
                        PopupNotice.insert({ data: resp.data })
                        g.showSuccess("Saved!")
                    })
                } else {
                    QuilityAPI.createPopupNotice(this.popup_notice).then(function (resp) {
                        PopupNotice.insert({ data: resp.data })
                        g.popup_notice = resp.data
                        g.showSuccess("Created!")
                    })
                }
            }
        },
        previewPopup() {
            this.showMessagePreview = true
        },
        previewSMS() {
            var g = this

            if (this.preview_phone_number.length < 10) {
                this.showError('Enter a valid phone number.')
                return
            } else {
                localStorage.setItem("preview_phone_number", this.preview_phone_number)
            }

            if (this.popup_notice.title == null && this.popup_notice.content == null) {
                this.showError('You must enter a message in order to send a preview sms.')
                return
            }

            QuilityAPI.previewPopupNoticeSMS(this.popup_notice, this.preview_phone_number).then(function (resp) {
                if (resp.error) {
                    g.showError(resp.error)
                    return;
                }
                g.showSuccess("Preview Sent")
            })

            this.show_preview_sms_form = false

        },
        validateSmsSettings() {
            if (!this.send_sms && !this.send_sms_only)
                return true

            if (!this.send_sms && this.send_sms_only) {
                this.showError('Send SMS must be enabled for SMS Only to take affect. Otherwise, turn SMS Only off.')
                return false;
            }

            if (this.send_sms && !this.popup_notice.active) {
                this.showError('Sending an SMS requires that you set Active to the on position. SMS messages are sent immediately and cannot be edited.')
                return false;
            }

            let today = new Date()
            if (this.popup_notice.startDate) {
                let startDate = new Date(this.popup_notice.startDate)
                if (startDate > today) {
                    this.showError('A future start date can not be used when sending an SMS. SMS messages are sent immediately upon saving the notice.')
                    return false;
                }
            }

            if (this.popup_notice.endDate) {
                let endDate = new Date(this.popup_notice.endDate)
                if (endDate < today) {
                    this.showError('A past end date can not be used when sending an SMS. SMS messages are sent immediately upon saving the notice.')
                    return false;
                }
            }
            return true
        },
        characterCount: function () {
            if (this.popup_notice.content == null) {
                return 0
            }

            if (typeof this.popup_notice.content === 'string') {
                return this.popup_notice.content.replace(/(<([^>]+)>)/gi, "").length
            }

            return null
        },

        // TODO: Move this method somewhere to utils, it's used in multiple places such as QEditStep
        /**
         * Is string contain only ASCII Extended
         * @see https://www.ascii-code.com/
         */
        isOnlyAsciiExtended(str) {
            // Allow ASCII extended for SMS. It includes \n, so disable no-control-regex
            // eslint-disable-next-line no-control-regex
            return /^[\x00-\xFF]*$/.test(str);
        },
        removeHtmlFromContent() {
            // minor: remove <p></p> tags that wsywig editor adds
            this.popup_notice.content = this.popup_notice.content?.replace(/^<p>(.*)<\/p>$/, "$1");
        }
    },
    watch: {
        '$route.params.id': function (newV, oldV) {
            this.popup_notice = { filters: {} };
            if (typeof newV != 'undefined') {
                this.loadNotice(newV);
            }
        },
        'needs_link': function (newV) {
            if (!newV) {
                var pn = this.popup_notice;
                pn.link = null
                pn.link_text = null
                PopupNotice.update({ data: pn });
            }
        },
        'popup_notice.audience': function (newV) {
            //this.$set(this.popup_notice, 'selected_agents', {});
            this.agent_search = null
            this.available_agents = Agent.query().orderBy('AgentName', 'asc').get();
            if (newV != 'agents') {
                this.$set(this.popup_notice, 'selected_agents', {});
            }
        },
        'agent_search': function (newV, oldV) {
            if (newV.length > 3 || newV.length < oldV.length) {
                this.loadAvailableAgents(this.popup_notice.audience)
            }
        },
        'send_sms': function (newV, oldV) {
            if (!newV) {
                this.$refs.form.validate()
            }

            this.removeHtmlFromContent()
        },
    },
    components: {
    QPopupSubmenu,
    TextEditor,
    QTooltipDetails,
    MinimalTextEditor,
    QAdminNotices,
    FormRenderer,
    MessageCard,
    FMLPopupContentEditor
}
};
</script>
<style scoped>
.result-list {
    height: 320px;
    overflow: scroll;
}
</style>

<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-product-library-admin-submenu></q-product-library-admin-submenu>
        <v-row justify="space-between" align="stretch" class="mx-5">
            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
            <v-col cols="12">
                <h3>Edit <span v-if="product">{{product.Product}}</span>
                    <v-btn class="ml-4" @click="saveProduct" color="primary">Save</v-btn>
                </h3>
                <v-row wrap dense v-if="product">
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Product Name" v-model="product.Product" :rules="[form_rules.required]"></v-text-field>
                        <v-autocomplete label="Carrier" item-value="value" item-text="text" v-model="product.CarrierID" :items="carriers" :rules="[form_rules.required]"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-select label="Product Solution" v-model="product.ProductSolution" :rules="[form_rules.required]" :items="product_solutions_select"></v-select>
                        <v-select v-if="product_types_select" label="Product Type" v-model="product.ProductType" :items="product_types_select"></v-select>
                        <v-select v-if="product_subtypes_select" label="Product Sub Type" v-model="product.ProductSubtype" :items="product_subtypes_select"></v-select>
                    </v-col>
                    
                    <v-col cols="12" sm="6" md="4">
                        <label class="v-label v-label--active theme--light" style="font-size:12px;display:block;margin-bottom:5px;margin-top:6px;">Product Codes</label>
                        <v-sheet outlined rounded class="pa-2">
                            <v-chip v-for="code in product.product_code" :key="code.ID"
                                close
                                small
                                outlined
                                link
                                @click="editProductCode(code)"
                                @click:close="removeProductCode(code)"
                                class="ma-1"
                                text-color="grey darken-2"
                            >{{code.ProductCode}}</v-chip>
                            
                            <v-chip v-if="product.ID > 0"
                                small
                                link
                                @click="addProductCode"
                                class="ma-1"
                                color="success"
                            >Add
                                <v-icon right color="white" size="12px !important">fas fa-plus-circle</v-icon>
                            </v-chip>
                            <span v-else class="body-2 text--disabled">Save the product to enable addition of product codes.</span>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="6" sm="4" md="2">
                                <v-checkbox label="eApp" v-model="product.eAppInd" false-value="0" true-value="1"></v-checkbox>
                            </v-col>
                            <v-col cols="6" sm="4" md="2">
                                <v-checkbox label="Paper App" v-model="product.PaperAppInd" false-value="0" true-value="1"></v-checkbox>
                            </v-col>
                            <v-col cols="6" sm="4" md="2">
                                <v-checkbox label="Instant Decision" v-model="product.InstantDecisionInd" false-value="0" true-value="1"></v-checkbox>
                            </v-col>
                            <v-col cols="6" sm="4" md="2">
                                <v-checkbox label="Flexible Premium" v-model="product.FlexiblePremiumPossibleInd" false-value="0" true-value="1"></v-checkbox>
                            </v-col>
                            <v-col cols="6" sm="4" md="2">
                                <v-checkbox label="NY" v-model="product.NY" false-value="0" true-value="1"></v-checkbox>
                            </v-col>
                            <v-col cols="6" sm="4" md="2">
                                <v-checkbox label="CA" v-model="product.CA" false-value="0" true-value="1"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <v-col cols="12">
                            <h4>Divisions</h4>
                        </v-col>
                        <div v-if="all_divisions.length > 0">
                            <v-row v-for="division in all_divisions" :key="division.ID" dense>
                                <v-col cols="4" class="pt-4 pl-4">{{division.Division}}</v-col>
                                <v-col cols="4"><v-checkbox label="Active" true-value="1" false-value="0" v-model="division.ActiveInd" /></v-col>
                                <v-col cols="4"><v-checkbox label="Hidden" true-value="1" false-value="0" v-model="division.HiddenInd" /></v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <v-col cols="12">
                            <v-text-field label="Email" v-model="product.Email"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Fax" v-model="product.Fax"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Guide" v-model="product.ProductGuide"></v-text-field>
                        </v-col>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea label="Notes" v-model="product.Notes"></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    
        <v-dialog v-model="dialogConfirmDeleteProductCode" width="500">
            <v-card>
                <v-card-title class="headline error" color="error">
                    <h4 class="q_white-text">Delete product code {{currentProductCode.ProductCode}}? </h4>
                </v-card-title>
                <v-card-text class="pt-3">
                    <v-progress-linear indeterminate v-if="updating"></v-progress-linear>
                    <p class="body">Are you sure you want to remove this product code?</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="ml-5" color="error" outlined @click="deleteProductCode()">
                        Delete
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-5" color="error" depressed @click="dialogConfirmDeleteProductCode = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showEditProductCode" width="500">
            <v-card class="pa-6 text-center" min-height="">
                <v-card-title>
                    <h4 class="q-display-1 pa-5 white--text text-center q_leads_1" style="min-width:100%;">
                        <span v-if="currentProductCode.ID == null">New Product Code</span>
                        <span v-else>Edit Product Code</span>
                    </h4>
                </v-card-title>
                <v-card-text>
                    <v-progress-linear indeterminate v-if="updating"></v-progress-linear>
                    <v-text-field label="Product Code" :rules="[form_rules.required]" v-model="currentProductCode.ProductCode"></v-text-field>
                    <v-row>
                        <v-btn depressed outlined class="ml-4" @click="cancelEditProduct">Cancel</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn right depressed color="primary" class="mr-4" @click="saveProductCode">Save</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QProductLibraryAdminSubmenu from '@/components/navigation/Submenus/QProductLibraryAdminSubmenu.vue'
import Page from '@/CMS/views/index.vue'
export default {
    name: "Products",
    data() {
        return {
            updating: false,
            product: null,
            loading: false,
            carriers: null,
            currentProductCode: {},
            oldProductCode: {},
            dialogConfirmDeleteProductCode: false,
            showEditProductCode: false,
            all_divisions: [],
            product_solutions: {
                'Accidental Death': {},
                'Debt Free Life': {},
                'Dental': {},
                'Disability & Critical Illness': {
                    'Health': [
                        '',
                        'Disability', 
                        'Critical Illness'
                    ],
                },
                'Group': {
                    'Term Life': [], 
                    'Accident Expense': [], 
                    'Hopital Indemnity': [], 
                    'Dental': [], 
                    'Medical Expense': [], 
                    'Universal Life': [], 
                    'Vision': [], 
                    'Whole Life': [], 
                    'Disability': [], 
                    'Critical Illness': []
                },
                'Mortgage Protection': {},
                'Permanent Life': {
                    'Universal Life': [],
                    'Whole Life': [
                        '',
                        'Children', 
                        'All'
                    ]
                },
                'Retirement Solutions': {
                    'Advanced Markets':[
                        '',
                        'Annuity',
                        'IUL'
                    ]
                },
                'Senior Solutions': {
                    'Whole Life': [
                        '',
                        'Final Expense'
                    ],
                    'Health': [
                        '',
                        'Med Supp',
	                    'Long Term Care'
                    ]
                },
                'Term Life': {
                    'Term Life': [
                        '',
                        'Children',
                        'All'
                    ]
                }
            }
        };
    },
    mounted: function() {
        if(this.product === null)
            this.loadProduct();
        if(this.carriers === null)
            this.loadCarriers();
    },
    computed: {
        "product_id": function() {
            return this.product && this.product.ID ? this.product.ID : this.$route.params.product_id
        },
        "product_solutions_select": function() {
            return Object.keys(this.product_solutions);
        },
        "product_types_select": function() {
            if(this.product === null || this.product.ProductSolution === "" || this.product.ProductSolution === null)
                return false;
            if(this.product && Object.keys(this.product_solutions[this.product.ProductSolution]).length > 0){
                return Object.keys(this.product_solutions[this.product.ProductSolution]);
            }
            return false
        },
        "product_subtypes_select": function() {
            if(this.product === null || this.product.ProductSolution === "" || this.product.ProductSolution === null)
                return false;
            // if(this.product && this.product_solutions[this.product.ProductSolution][this.product.ProductType].length > 0){
            if(this.product_types_select !== false && this.product_solutions[this.product.ProductSolution][this.product.ProductType] !== undefined  && this.product_solutions[this.product.ProductSolution][this.product.ProductType].length > 0) {
                return this.product_solutions[this.product.ProductSolution][this.product.ProductType];
            }
            return false
        },
    },
    methods: {
        loadProduct: function() {
            this.product = null;
            if (this.product_id == null || this.loading === true) {
                return;
            }
            if (this.product_id == 'new'){
                this.product = {
                    ID: 'new',
                    CA: 0,
                    CarrierID: "",
                    CreateDate: "",
                    Email: "",
                    Fax: "",
                    FlexiblePremiumPossibleInd: 0,
                    InstantDecisionInd: 0,
                    LastChangeBy: "",
                    LastChangeDate: "",
                    NY: 0,
                    Notes: "",
                    PaperAppInd: 0,
                    Product: "",
                    ProductGuide: null,
                    ProductSolution: null,
                    ProductSubtype: null,
                    ProductType: null,
                    eAppInd: 0,
                    divisions: []
                }
                return;
            }
            var g = this
            this.loading = true
            QuilityAPI.getProduct(this.product_id).then(function(json) {
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                json.data.CarrierID = parseInt(json.data.CarrierID)
                g.$set(g, 'product', json.data);
                g.loading = false
                g.loadAllDivisions()
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
        },
        saveProduct: function() {
            var g = this
            
            if(!this.product.Product.length > 0) {
                g.showError('Please enter Product Name')
                return
            } else if(!this.product.CarrierID > 0) {
                g.showError('Please select Carrier')
                return
            } else if(this.product.ProductSolution === null) {
                g.showError('Please select Product Solution')
                return
            }

            this.loading = true
            if(this.product_id === 'new'){
                QuilityAPI.createProduct(this.product).then(function(json) {
                    g.loading = false
                    if (typeof json.response != 'undefined' && json.response.clientError) {
                        g.showError(json.response.body.message);
                        return
                    }
                    g.$set(g, 'product', json.data);
                    g.showSuccess("Product Created!");
                    localStorage.setItem("refreshProducts", true);
                    g.product.divisions = g.all_divisions
                    QuilityAPI.updateDivisionCarrierProduct(g.product)
                });
                return
            }
            QuilityAPI.updateProduct(this.product).then(function(json) {
                g.loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, 'product', json.data);
                g.showSuccess("Saved!");
                g.product.divisions = g.all_divisions
                QuilityAPI.updateDivisionCarrierProduct(g.product)
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
        },
        loadCarriers: function(){
            var g = this
            this.loading = true

            QuilityAPI.getPossibleCarrierNames().then(function(json) {
                g.loading = false
                g.carriers = json.map(carrier => {
                    let possibleCarrierNames = carrier.PossibleCarrierNames.split(";")
                    let carrierText = carrier.CarrierName + " - " + possibleCarrierNames.join(", ")
                    return { text: carrierText, value: carrier.CarrierID }
                })
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
        },
        addProductCode: function() {
            this.showEditProductCode = true
            this.currentProductCode = {
                CarrierProductID: this.product.ID,
                CarrierID: this.product.CarrierID,
                ActiveInd: 1
            }
        },
        saveProductCode: function() {
            this.updating = true
            var g = this
            QuilityAPI.createProductCode(this.currentProductCode)
                .then(response => {
                    
                    this.updating = false
                    this.showEditProductCode = false

                    if(response.error){
                        g.showError(response.msg)
                        return
                    }

                    if(this.currentProductCode.ID === undefined) {
                        this.product.product_code.push(response.data)
                    } else {
                        this.product.product_code.map(code => {
                            if(code.ID == g.currentProductCode.ID)
                                code.ProductName = g.currentProductCode.ProductName
                            return code
                        })
                    }
                })
                .catch(err => {
                    this.dialogConfirmDeleteProductCode = false
                    this.updating = false
                    g.showError(err.message)
                })
        },
        editProductCode: function(code) {
            this.currentProductCode = code
            this.showEditProductCode = true
            this.oldProductCode = { ...code }
        },
        removeProductCode: function(code) {
            this.currentProductCode = code
            this.dialogConfirmDeleteProductCode = true
        },
        deleteProductCode: function() {
            var g = this
            g.updating = true
            QuilityAPI.deleteProductCode(this.currentProductCode.ID)
                .then(response => {
                    this.dialogConfirmDeleteProductCode = false
                    this.updating = false
                    if(response.error){
                        g.showError(response.msg)
                        return
                    }
                    this.product.product_code = this.product.product_code.filter(code => code.ID !== this.currentProductCode.ID)
                })
                .catch(err => {
                    this.dialogConfirmDeleteProductCode = false
                    this.updating = false
                    g.showError(err.message)
                })
        },
        cancelEditProduct: function() {
            if(this.currentProductCode.ID !== undefined) {
                this.currentProductCode.ProductCode = this.oldProductCode.ProductCode
            }
            this.showEditProductCode = false
        },
        loadAllDivisions: function() {
            var g = this

            QuilityAPI.getDivisions().then(function(json) {
                g.all_divisions = json.data
                g.setUpDivisions()
            })
            
        },
        setUpDivisions: function() {
            let g = this
            g.all_divisions.map(div => {
                // see if this division exists in the carrier divisions
                let this_div = g.product.divisions.find(division => {
                    return division.DivisionID == div.ID
                })
                
                //set the initial values
                if(this_div !== undefined) {
                    div.ActiveInd = this_div.ActiveInd
                    div.HiddenInd = this_div.HiddenInd
                    div.DivisionCarrierProductID = this_div.ID
                }
            })
        }
    },
    components: {
        QProductLibraryAdminSubmenu,
        Page
    },
    watch: {
        'product_id': function(newV, oldV) {
            if (newV == null || oldV === 'new' || parseInt(oldV) == parseInt(newV)) {
                return
            }
            this.product = null;
            this.$nextTick(this.loadProduct)
        },
        '$route.params.product_id': function(newV, oldV) {
            if (newV == null || oldV === 'new' || parseInt(oldV) == parseInt(newV)) {
                return
            }
            if(newV > 0 || newV === 'new') {
                this.loadProduct()
            }
        },
        'product.ProductSolution': function(newV, oldV) {
            if(oldV == undefined)
                return
            if(this.product === null || this.product === undefined || this.product.ProductSolution === null || this.product.ProductSolution === undefined)
                return;
            if(this.product_solutions[this.product.ProductSolution][this.product.ProductType] === undefined) {
                this.product.ProductType = null
                this.product.ProductSubtype = null
            } else if(this.product_solutions[this.product.ProductSolution][this.product.ProductType][this.product.ProductSubtype] === undefined) {
                this.product.ProductSubtype = null
            }
        },
    }
};

</script>

<template>
    <div>
        <div class="my-4">
            <v-btn class="py-6 px-5" color="q_messages_1" depressed :small="$vuetify.breakpoint.smAndDown" :to="{name:'PopupNoticeView', params:{ id:'new' }}">
                <v-icon class="px-3" small left>fas fa-pen</v-icon> COMPOSE MESSAGE
            </v-btn>
        </div>
        <v-card>
            <QDataTableHeader color="q_messages_3" :id="entity + '_datatable'" class="px-5">
                <template #left>
                    <v-row>
                        <v-col cols="12" sm="7">
                            <v-row>
                                <v-col class="py-0">
                                    <div class="q-display-3 d-flex align-center white--text">
                                        {{ title }}
                                        <v-btn icon class="ml-4" @click="resetData" dark>
                                            <v-icon small>fas fa-sync-alt</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        
                    </v-row>
                </template>
                <template #right>
                     <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                </template>
            </QDataTableHeader>

            <v-row class="d-flex justify-start mx-5 py-5">
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn text color="blue" v-on="on">
                            <v-icon>fas fa-filter</v-icon>
                            | Filters
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="filter in filterList" :key="filter.key">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="item-title pl-2">
                                        <v-checkbox v-model="filters[filter.key]" :label="filter.label" />
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                 </v-menu>
            </v-row>
            <QExpandableDataTable v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :visibleHeaders="visibleHeaders" :items="all_data" item-key="$id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items">
                <template v-slot:item.Email="{ item }">
                    <a :href="'mailto:' + item.Email" class="nowrap">{{ item.Email }}</a>
                </template>
                <template v-slot:item.id="{ item }">
                    <span class="nowrap">
                        {{ formatPercentage(item.acknowledged_count / item.audience_count) }}
                    </span>
                </template>
                <template v-slot:item.$id="{ item }">
                    <span class="nowrap">
                        <v-btn small text class="q_new_b_1--text white--text  body-2" :to="'/admin/sent-messages/' + item.id">
                            <v-icon small>fas fa-pencil-alt</v-icon>
                        </v-btn>
                        <v-btn small text class="body-2" :to="'/admin/sent-messages/' + item.id + '/log'">
                            <v-icon small>fas fa-th-list</v-icon>
                        </v-btn>
                        <v-btn small text class="red--text body-2" @click="deleteNotice(item.id)">
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>
                    </span>
                </template>
                <template v-slot:item.created_at="{ item }">
                    <span class="nowrap">
                        {{ formatDate(item.created_at) }}
                    </span>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-icon small v-if="item.active == 0" color="red">fas fa-times</v-icon>
                    <v-icon small v-else color="success">fas fa-check</v-icon>
                </template>
                <template v-slot:item.startDate="{ item }">
                    <span class="nowrap">
                        {{ formatDate(item.startDate) }}
                    </span>
                </template>
                <template v-slot:item.endDate="{ item }">
                    <span class="nowrap">
                        {{ formatDate(item.endDate) }}
                    </span>
                </template>
                <!--
                <template v-slot:header.data-table-select="{item}">
                    <div>
                        <v-tooltip :open-on-hover="true" top>
                            <template v-slot:activator="{ on }">
                                <v-checkbox v-model="selectAll" v-on="on"></v-checkbox>
                            </template>
                            <span>Add All to Power Dailer</span>
                        </v-tooltip>
                    </div>
                </template>
                //-->
                <template v-slot:body.append>
                    <tr>
                        <td colspan=10>
                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td colspan=2>
                            <v-btn dark color="q_leads_1" v-on:click="dialog = true">Send to Power Dialer</v-btn>
                        </td>
                        <td colspan=2>
                            <v-btn dark color="q_leads_1" v-on:click="showSyncDialog">Sync Disposition</v-btn>
                        </td>
                    </tr>-->
                </template>
            </QExpandableDataTable>
        </v-card>
        <v-dialog v-model="dialog" width="400">
            <v-card class="pa-6 text-center" min-height="">
                <div class="pa-4 display-3 font-weight-bold">Oops...</div>
                <p>I don't see a Power Dialer account for you.</p>
                <v-btn color="primary">Purchase Power Dialer</v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin"
import PopupNotice from '@/store/Models/PopupNotice'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import QDataTableHeader from '../datatables/base/QDataTableHeader.vue'
import QExpandableDataTable from './QExpandableDataTable.vue'

export default {
    name: "QPopupDataTable",
    mixins: [QDataTableMixin],
    components: {
        QDataTableHeader, QExpandableDataTable
    },

    data: function() { // data internal to component (not available outside)
        return {
            entity: 'popup_notice',
            sync_dialog: false,
            //use this to "preset" a filter
            filterList: [
                {
                    key: 'hide_summit',
                    label: 'Hide Summit',
                },
                {
                    key: 'hide_onboarding',
                    label: 'Hide Onboarding',
                },
                {
                    key: 'hide_sb',
                    label: 'Hide Switchboard',
                },
            ],
            filters: {
                hide_summit: true,
                hide_onboarding: true,
                hide_sb: true,
            },
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            options: { sortBy: ['created_at'], sortDesc: [true] },
        }
    },
    computed: {
        filter_keys: function() {
            return PopupNotice.state().filter_keys;
        },
        all_data: function() {
            return PopupNotice.query().orderBy(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc').get()
        },
        the_headers: function() {
            return [{
                    text: 'Title',
                    value: 'title',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    class: 'header-class',
                    //width: '100',
                    //filter: function(value, search, item) {
                    ///    console.log(value, search, item)
                    //    return true;
                    //}
                    //sort : function(a, b){
                    //    console.log(value, search, item)
                    //}
                },
                {
                    text: 'Creator',
                    value: 'AgentName',
                    sortable: false,
                    filterable: true
                },
                {
                    text: 'Acknowledged',
                    value: 'acknowledged_count',
                    sortable: false,
                    filterable: true
                },
                {
                    text: 'Targeted',
                    value: 'audience_count',
                    sortable: false,
                    filterable: true
                },
                {
                    text: '%',
                    value: 'id',
                    sortable: false,
                    filterable: true
                },
                {
                    text: 'Created',
                    value: 'created_at',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Start',
                    value: 'startDate',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'End',
                    value: 'endDate',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Active',
                    value: 'active',
                    sortable: true,
                    filterable: true,
                    align: 'center'
                },
                {
                    text: '',
                    value: '$id',
                    sortable: false,
                    filterable: false
                }
            ]
        },

        visibleHeaders() {
            if (this.$vuetify.breakpoint.xs) {
                return this.the_headers.slice(0, 2)
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                return this.the_headers.slice(0, 3)
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return this.the_headers.slice(0, 6)
            }

            return this.the_headers
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            QuilityAPI.getPopupNotices(this.filters, opt);
            this.firstPull = false;
        }, 200),
        showSyncDialog: function() {
            this.sync_dialog = true;
            var g = this;
            setTimeout(function() { g.sync_dialog = false }, 4500);
        },
        resetData() {
            this.search = null;
            this.refreshData(this.search)
        },
        deleteNotice(id) {
            QuilityAPI.deletePopupNotice(id);
        }
    },
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
